<template>
  <div class="row">
    <b-modal
    v-if="showDetailModalControl"
    id="display-detail-modal"
    ref="display-detail-modal"
    hide-header
    hide-header-close
    scrollable
    size="md"
  >
    <div class="row mx-0 py-7">
      <div class="col-12 mx-0 py-2 text-xl">
        <template>
          <div
            class="col-12 py-2 px-0 text-center text-xl font-weight-boldest navy-blue-border-bottom-1px bg-fine"
          >
            {{ $t("following.general_total") }}
          </div>
          <div class="col-12 d-flex px-0 justify-content-center">
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
            >
              {{ $t("following.target") }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px"
            >
              {{ $t("following.standing") }}
            </div>
          </div>
          <div
            class="col-12 d-flex px-0 justify-content-center navy-blue-border-bottom-1px"
          >
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
            >
              {{ followingProductionDetails.totals.total_target }}
            </div>
            <div class="w-100px py-1 text-center font-weight-bold">
              {{ followingProductionDetails.totals.total_iron_actual }}
            </div>
          </div>
        </template>

        <div
          v-if="showDetailType == 1"
          class="col-12 py-2 px-0 text-center text-xl font-weight-boldest navy-blue-border-bottom-1px bg-fine"
        >
          {{ $t("following.company_based_grand_totals_of_the_order") }}
        </div>

        <template
          v-if="showDetailType == 1"
          v-for="orderItem in followingProductionDetails.orders"
        >
          <div>
            <div
              class="col-12 py-2 px-0 text-center text-xl text-primary font-weight-boldest navy-blue-border-bottom-1px"
            >
              {{ orderItem.supplier_company_name }}
              
            </div>
            <div class="col-12 d-flex px-0 justify-content-center">
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("following.order_number") }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("following.target") }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px"
              >
                {{ $t("following.standing") }}
              </div>
            </div>
            <div
              class="col-12 d-flex px-0 justify-content-center navy-blue-border-bottom-1px"
              v-for="orderSubItem in orderItem.orders"
            >
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
              >
                {{ orderSubItem.order_number }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
              >
                {{ orderSubItem.target }}
              </div>
              <div class="w-100px py-1 text-center font-weight-bold">
                {{ orderSubItem.ironActual }}
              </div>
              <div class="w-100px py-1 text-center font-weight-bold" v-if="false">
                -
              </div>
            </div>
          </div>
        </template>

        <div
          v-if="showDetailType == 2"
          class="col-12 py-2 px-0 text-center text-xl font-weight-boldest navy-blue-border-bottom-1px bg-fine"
        >
          {{ $t("following.company_orders") }}
        </div>

        <template
          v-if="showDetailType == 2"
          v-for="orderItem in followingProductionDetails.orders"
        >
          <div>
            <div
              class="col-12 py-2 px-0 text-center text-xl text-primary font-weight-boldest navy-blue-border-bottom-1px"
            >
              {{ orderItem.supplier_company_name }}
            </div>
            <div class="col-12 d-flex px-0 justify-content-center">
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("following.order_number") }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              >
                {{ $t("following.target") }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px"
              >
                {{ $t("following.standing") }}
              </div>
            </div>
            <div
              class="col-12 d-flex px-0 justify-content-center navy-blue-border-bottom-1px"
            >
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
              >
                {{ orderItem.order_number }}
              </div>
              <div
                class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
              >
                {{ orderItem.target }}
              </div>
              <div class="w-100px py-1 text-center font-weight-bold">
                {{ orderItem.ironActual }}
              </div>
              <div class="w-100px py-1 text-center font-weight-bold" v-if="false">
                -
              </div>
            </div>
          </div>
        </template>
        <div
          class="col-12 py-2 px-0 text-center text-xl font-weight-boldest navy-blue-border-bottom-1px bg-fine"
        >
          {{ $t("following.order_list_of_companies") }}
        </div>
        <template v-for="(item, key) in followingProductionDetails.items">
          <div
            class="col-12 py-2 px-0 text-center text-xl text-primary font-weight-boldest navy-blue-border-bottom-1px"
          >
            {{ key + 1 }}-{{ item.supplier_company_name }}
          </div>
          <div class="col-12 d-flex px-0 justify-content-center">
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
            >
              {{ $t("following.date") }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
            >
              {{ $t("following.order_number") }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
            >
              {{ $t("following.target") }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              v-if="false"
            >
              {{ $t("following.interrupted") }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px"
            >
              {{ $t("following.standing") }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px"
              v-if="false"
            >
              {{ $t("following.iron") }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-bottom-1px"
              v-if="false"
            >
              {{ $t("following.export") }}
            </div>
          </div>
          <div
            class="col-12 d-flex px-0 justify-content-center navy-blue-border-bottom-1px"
          >
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
            >
              {{ item.date }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
            >
              {{ item.order_number }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
            >
              {{ item.target }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
              v-if="false"
            >
              {{ item.cutActual }}
            </div>
            <div class="w-100px py-1 text-center font-weight-bold">
              {{ item.ironActual }}
            </div>
            <div
              class="w-100px py-1 text-center font-weight-bold navy-blue-border-right-1px"
              v-if="false"
            >
              {{ item.plantingActual }}
            </div>

            <div class="w-100px py-1 text-center font-weight-bold" v-if="false">-</div>
          </div>
        </template>
      </div>
    </div>
    <template #modal-footer>
      <div class="col-12 text-center">
        <button
          class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
          @click="closeModal('display-detail-modal')"
        >
          {{ $t("general.close") }}
        </button>
      </div>
    </template>
  </b-modal>
    <div class="col-12">
      <div class="w-100 row mx-0 px-0 d-flex justify-content-center">
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 px-0 d-flex justify-content-center"
        >
          <div class="col-lg-8 col-md-8 my-lg-2 row">
            <div class="col-lg-3 col-md-6 col-sm-12 my-2 my-md-0">
              <date-filter
                :helper-text="$t('general.start_date')"
                title="general.start_date"
                :model.sync="filters.start_date"
                name="start_time"
              >
              </date-filter>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 my-2 my-md-0">
              <date-filter
                :helper-text="$t('general.end_date')"
                title="general.end_date"
                :model.sync="filters.end_date"
                name="end_date"
              >
              </date-filter>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 my-2 my-md-0 py-1">
              <custom-multi-select
                :isInline="false"
                :model.sync="filters.supplier_company_ids"
                :options="supplierCompany"
                :required="true"
                :title="$t('following.supplier_company_filter')"
                name="supplier_company_filter"
                :multiple="true"
                @save="getCompanyIndex($event)"
              ></custom-multi-select>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 my-2 my-md-0 py-1"> 
              <custom-multi-select
                :isInline="false"
                :model.sync="filters.supplier_order_ids"
                :options="supplierOrders"
                :required="true"
                :title="$t('Siparişe Göre')"
                name="supplier_order_filter"
                :multiple="true"
                @save="getOrderIndex($event)"
              ></custom-multi-select>
            </div>
          </div>
          <div
            class="col-xl-2 col-lg-2 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
            style="margin: 0 !important"
          >
            <div class="col-12 pr-0">
              <button
                class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1 mt-1"
                @click="filterResult(null)"
              >
                {{ $t("general.filter") }}
              </button>
              <button
                type="button"
                @click="resetFilters()"
                class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1 mt-1"
              >
                {{ $t("general.clear") }}
              </button>
              <button
                @click="exportPackingListTable()"
                type="button"
                class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1 mt-1"
              >
                {{ $t("general.export_to_excel") }}
              </button>
              <!-- TODO: pdf listeye aktar -->
              <button
                type="button"
                v-if="false"
                @click="exportPackingListPdf()"
                class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1 mt-1"
              >
                {{ $t("general.export_to_list") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="w-100 row mx-0 px-0 d-flex justify-content-center">
        <table class="table table-responsive" ref="table">
          <thead class="main-table" ref="thead">
            <tr>
              <th class="font-weight-bold text-xl">
                <div class="w-100px text-center">
                  {{ $t("general.date") }}
                </div>
              </th>
              <th
                scope="col"
                class="py-1 font-weight-bold text-xl justify-content-center"
                v-for="tableItem in tableItems"
              >
                <div class="text-center">
                  {{ tableItem[0]["supplier_company_name"] }}
                  <i
                    class="fa fa-eye fa-1x pr-1"
                    role="button"
                    @click="showDetailModal($event, tableItem[0], 2)"
                  >
                  </i>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="date in filterResultDates">
              <th
                class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px m-0 p-0 w-100px align-items-center"
              >
                <span class="date">{{ date }}</span>
              </th>
              <td
                v-for="tableItem in tableItems"
                class="justify-content-center navy-blue-border-bottom-1px navy-blue-border-right-2px m-0 p-0"
              >
                <table class="d-flex justify-content-start"  >
                  <tbody class="inner-table">
                    <tr>
                      <template
                        v-for="dateInTableItems in tableItem"
                        v-if="dateInTableItems.date == date"
                      >
                        <td
                          class="text-center"
                          style="border-top: transparent"
                        >
                        <div class="order-number">
                          <span class="m-o p-0 m-0 p-1 text-lg">{{
                            dateInTableItems.order_number
                          }}
                          <i
                          class="fa fa-eye fa-1x pr-1"
                          role="button"
                          @click="showDetailModal($event, dateInTableItems, 1)"
                        >
                        </i>
                        </span>
                          
                        </div>
                        </td>
                        <td
                          class="text-center"
                          style="border-top: transparent"
                        >
                          <div class="order-info-area text-center">
                            <div class="target-area">
                              <span class="m-o p-0 text-sm">
                                {{ $t("following.target") }}:
                              </span>
                              <span class="m-o p-0 text-sm">{{
                                Number(dateInTableItems.target).toLocaleString('tr-TR')
                              }}</span>
                            </div>
                          </div>
                        </td>
                        <td
                          class="text-center font-weight-bold navy-blue-border-right-2px"
                          style="border-top: transparent"
                        >
                          <div class="order-info-area text-center">
                            <div class="actual-area">
                              <span class="m-o p-0 text-sm"
                                >{{ $t("following.standing") }}:
                              </span>
                              <span class="m-o p-0 text-sm">{{
                                Number(dateInTableItems.ironActual).toLocaleString('tr-TR')
                              }}</span>
                            </div>
                          </div>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
} from "@/core/services/store/REST.module";
import {
  EXPORT,
  EXPORT_PDF,
} from "@/core/services/store/following-production/following_production.module";
import moment from "moment";
import {
  LARAVEL_DATE_FORMAT,
  LARAVEL_DATE_TIME_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";
import DataTable from "@/assets/components/dataTable/DataTable";

import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/holidays-us.css";
import "vue-simple-calendar/static/css/gcal.css";
// TODO: remove import and delete file
import { CalendarMathMixin, CalendarView, CalendarViewHeader } from "vue-simple-calendar"; // published version
import Vue from "vue";
import VueExcelEditor from "vue-excel-editor";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import TextInput from "@/assets/components/inputs/TextInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {FOLLOWING_PRODUCTION_TABLE_END_DATE, FOLLOWING_PRODUCTION_TABLE_START_DATE} from "@/core/storage/storage-names"

export default {
  name: "FollowingProductionTableView",
  components: {
    TextInput,
    InputFilter,
    DashboardBox,
    DataTable,
    CalendarView,
    CalendarViewHeader,
    DateFilter,
    CustomMultiSelect,
    SelectFilter,
  },
  mixins: [CalendarMathMixin],
  beforeRouteLeave(to, from, next) {
    this.config.content.width = "fixed";
    next();
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      layoutConfig: ["layoutConfig"],
    }),
    config() {
      return this.layoutConfig();
    },
  },
  data() {
    return {
      supplierCompany: [],
      supplierOrders: [],
      tableItems: [],
      isShowingPopUp: false,
      showDetailType: null,
      showDetailModalControl: false,
      popShowItemDetailsItem: [],
      filterResultDates: [],
      followingProductionDetails:[],
      filters: {
        search: null,
        start_date: moment().subtract(30, "d"),
        end_date: moment().add(1, "d"),
        supplier_company_ids: [],
        supplier_order_ids: [],
      },
    };
  },
  methods: {
    getCompanyIndex(companyId) {
      return this.tableItems.findIndex((item) => item.id === companyId);
    },
    getOrderIndex(orderId) {
      return this.tableItems.findIndex((item) => item.id === orderId);
    },
    resetFilters() {
      this.filters = {
        search: null,
        start_date: moment(this.showDate).subtract(1, "days"),
        end_date: moment(this.showDate).add(1, "month"),
        supplier_company_ids: [],
        supplier_order_ids: [],
      };
    },
    filterResult() {
      localStorage.setItem(FOLLOWING_PRODUCTION_TABLE_END_DATE, moment(this.filters.start_date));
      localStorage.setItem(FOLLOWING_PRODUCTION_TABLE_START_DATE, moment(this.filters.end_date));
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "supplier_company_ids",
        this.filters.supplier_company_ids.join(",")
      );
      this.$set(
        filters,
        "supplier_order_ids",
        this.filters.supplier_order_ids.join(",")
      );
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/following-production/get_order_expense_target_list",
          acceptPromise: true,
          filters: filters,
          showLoading: true,
        })
        .then((result) => {
          if (result.status) {
            let items = result.data;
            const sortedData = Object.entries(items.data)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB, 'tr'))
            .reduce((acc, [key, value]) => {
              acc[key] = value;
              return acc;
            }, {});

           
            this.tableItems = sortedData;
            this.filterResultDates = items.dates;
          }
        });
    },
    exportPackingListTable() {
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "supplier_company_ids",
        this.filters.supplier_company_ids.join(",")
      );
      this.$set(
        filters,
        "supplier_order_ids",
        this.filters.supplier_order_ids.join(",")
      );
      this.$store
        .dispatch(EXPORT, {
          filters: filters,
        })
        .then((result) => {
          if (result.status) { 
            let fileURL = window.URL.createObjectURL(new Blob([result.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "following_production_list" + day + ".xlsx";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    exportPackingListPdf() {
      let filters = {
        start_date: moment(this.selected_start_date).format(LARAVEL_DATE_FORMAT),
        end_date: moment(this.selected_end_date).format(LARAVEL_DATE_FORMAT),
      };
      this.$store
        .dispatch(EXPORT_PDF, {
          filters: filters,
        })
        .then((result) => {
          if (result.status) {
            let fileURL = window.URL.createObjectURL(new Blob([result.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "following_production_list" + day + ".pdf";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    closeModal(name = "display-detail-modal") {
      this.$refs[name].hide();
    },
    showDetailModal(e, payload, type) {
      //type 1 ise order_number'a göre getiriyor, 2 ise supllier_compnay'e göre getiriyor
      this.showDetailType = type;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/following-production/get_order_expense_detail",
          acceptPromise: true,
          filters: {
            supplier_company_id: type == 2 ? payload.supplier_company_id : null,
            order_number: type == 1 ? payload.order_number : null,
          },

          showLoading: true,
        })
        .then((result) => {
          if (result.status) {
            this.followingProductionDetails = result.data; 
            this.showDetailModalControl = true;
            setTimeout(() => {
              this.$refs["display-detail-modal"].show();
            }, 500);
          }
        });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.following_production"),
        route: "following_production.index",
      },
      { title: this.$t("general.dashboard") },
    ]);
    if (!this.isUserGranted("FollowingProduct", ["viewAny"])) {
      return false;
    }

    if(!this.filters){
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(FOLLOWING_PRODUCTION_TABLE_START_DATE)
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_TABLE_START_DATE))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(FOLLOWING_PRODUCTION_TABLE_END_DATE)
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_TABLE_END_DATE))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);

    this.config.content.width = "fluid";

    this.filterResult();
    let self = this;
    this.$store
      .dispatch(GET_ITEMS, {
        url: "api/planting-companies",
        acceptPromise: true,
        filters: { all: true },
        showLoading: true,
      })
      .then((result) => {
        if (result.status) {
          console.log(result.data)
          self.supplierCompany = self.convertArrayToObjectByKey(
            result.data,
            "id",
            "name"
          );
        }
      });
      
    this.$store
      .dispatch(GET_ITEMS, {
        url: "api/report/order-list",
        acceptPromise: true,
        filters: { all: true },
        showLoading: true,
      })
      .then((result) => {
        if (result.status) {
          console.log(result.data)
          self.supplierOrders = result.data
        }
      }); 
  },
};
</script>

<style lang="scss" scoped>
$cut_color: #e097ff;
$knit_color: #ffac70;
$iron_color: #ff95d5;
$primary_color: #462985;

$yellow_color: #f3f0d6;
$green_color: #d6f3eb;
$pink_color: #ffd5d7;

.inner-table {
  td {
    padding: 0px;
    span {
      display: grid;
      padding: 2px;
    }
  }
  td:last-child {
    border-right: none !important;
  }
}
.order-info-area {
  display: flex;
  flex-direction: column;
  
  span {
    margin-top: 0px;
  }
}
.table-responsive{
  height: 550px;
  tbody{
    tr{
      th{
        position: sticky !important;
        left: 0px !important;
        background-color:white !important;
      }
    }
  }
}
.main-table {
  table-layout: fixed;
  word-wrap: break-word;
  thead{
    th{
      div{
        min-width: 200px !important;
        width: 100% !important;
      }
    }
  }
  tr {
    z-index: 1;
    color: white;
    background-color: $primary_color;
    border-color: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);

    th {
      border-right: 2px solid white;
      font-weight: 900 !important;
    }
  }
}
.order-number{
  display: flex !important;
  flex-direction: column !important;
  margin-left: 5px;
  background-color: $pink_color;
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 700 !important;
}
.target-area {
  text-align: center;
  background-color: $green_color;
}
.actual-area {
  text-align: center;
  margin-right: 5px;
  background-color: $yellow_color;
}
.date {
  font-weight: 600;
}
.fixed{
  position: fixed;
  top: 25px;
  z-index: 1;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0,0,0,.1);
  transition: all 0.3s ease;
}

</style>
